import React, { useEffect, useRef, useState } from 'react';
import Input from 'components/Input';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlus, faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'store/modal/modal.store';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import AddFixedBenefitsForm from 'modules/benefit/UI/AddFixedBenefitsForm';
import EditFixedBenefitForm from 'modules/benefit/UI/EditFixedBenefitForm';
import {benefitActions, deleteFixedBenefit, getFixedBenefits} from 'modules/benefit/services/benefit.store';

const FixedBenefits = () => {

  const dispatch: any = useDispatch();
  const count: any = useSelector((state: any) => state.benefitStore.count);
  const nextPage: any = useSelector((state: any) => state.benefitStore.nextPage);
  const fixedBenefits: any = useSelector((state: any) => state.benefitStore.fixedBenefits);
  const searchString: any = useSelector((state: any) => state.benefitStore.searchString);
  const [loading, setLoading] = useState(false);

  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === fixedBenefits.length;
  }, [count, fixedBenefits]);

  const openAddEmployeeForm = () => {
    let modalContent: React.JSX.Element = (
      <AddFixedBenefitsForm />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Dodaj fiksni benefit',
          size: 'regular',
        },
      })
    );
  };

  const openEditEmployeeForm = (benefit: any) => {
    let modalContent: React.JSX.Element = (
      <EditFixedBenefitForm benefit={benefit} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Ažuriraj fiksni benefit',
          size: 'regular',
        },
      })
    );
  };

  const setSearchStringHandler = (payload: string) => {
    dispatch(benefitActions.setSearchString(payload));
  }

  const fetchMoreFixedBenefits = async (reset: boolean): Promise<void> => {
    try {
      await dispatch(getFixedBenefits(reset));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const openDeletePrompt = (benefitId: number): void => {
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši benefit"
        description="Da li zaista želiš da izbrišeš benefit?"
        onDelete={() => deleteFixedBenefitHandler(benefitId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const deleteFixedBenefitHandler = (benefitId: any): void => {
    try {
      dispatch(deleteFixedBenefit(benefitId));
      dispatch(modalActions.closeModal());
      toast.success('Uspešno ste izbrisali benefit!');
    } catch (e: any) {
      dispatch(modalActions.closeModal());
      toast.error('Nešto nije u redu!');
    }
  };

  function stripHtmlTags(input: string): string {
    if (input) return input.replace(/<\/?[^>]+(>|$)/g, "");
    else return input;
  }

  useEffect(() => {
    return () => {
      dispatch(benefitActions.setNextPage(null));
      dispatch(benefitActions.setCount(0));
      dispatch(benefitActions.resetFixedBenefits());
    }
  }, []);

  const resetBenefits = async () => {
    await dispatch(benefitActions.resetFixedBenefits());
  }

  useEffect(() => {
    resetBenefits();
    fetchMoreFixedBenefits(true);
  }, [searchString]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        fetchMoreFixedBenefits(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreFixedBenefits(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(benefitActions.setCount(0));
    dispatch(benefitActions.setNextPage(null));
    dispatch(benefitActions.resetFixedBenefits());
  }, []);

  return (
    <>
      <section className="flex items-center gap-x-5">
        <div>
          <Button
            bg="primary"
            className="w-56"
            size="regular"
            onClick={openAddEmployeeForm}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Dodaj fiksni benefit
          </Button>
        </div>
        <div className="w-96">
          <Input
            isSearch
            type="text"
            height="regular"
            debounceTime={400}
            classNames="w-full"
            placeholder="Pretraži fiksne benefite po nazivu"
            onChange={setSearchStringHandler}
          />
        </div>
      </section>

      <ul className="my-9 space-y-4">
        {fixedBenefits.length > 0 && fixedBenefits.map((benefit: any) =>
          <li
            key={benefit.id}
            className="bg-white hover:bg-gray-50 flex items-center justify-between gap-x-5 shadow-sm rounded-lg px-5 h-24 transform hover:scale-[1.02] animation duration-200 ease-in-out">
            <div className="w-1/2">
              <div className="flex items-center gap-x-3 truncate">
                <div>
                  {benefit.pictures && benefit.pictures.length > 0 ? (
                    <img
                      src={benefit.pictures[0].image}
                      alt="benefit"
                      className="w-28 h-16 rounded-lg border"
                    />
                  ) : (
                    <div className="border bg-white w-28 h-16 rounded-lg flex items-center justify-center">
                      <img
                        src="/hob-logo.png"
                        alt="benefit"
                        className="w-24 rounded-lg transform scale-[0.85]"
                      />
                    </div>
                  )}
                </div>
                <p className="text-lg font-medium text-base-600 truncate">{benefit.name}</p>
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-between">
              <div className="w-9/12 mr-5">
                <div className="font-medium text-gray-500 text-ellipsis text-wrap text-sm truncate h-[74px] flex items-center overflow-hidden gap-x-2">
                  <p className="flex items-start max-h-[75px]">{stripHtmlTags(benefit.description)}</p>
                </div>
              </div>
              <div className="w-24 flex items-center justify-end gap-x-2 ml-auto">
                <button
                  onClick={() => openEditEmployeeForm(benefit)}
                  data-tooltip-id="edit"
                  data-tooltip-content="Izmeni"
                  className="h-9 w-9 rounded-full flex items-center justify-center bg-base-600 hover:bg-base-500 text-white animation duration-200 ease-in-out">
                  <Tooltip id="edit"/>
                  <FontAwesomeIcon icon={faPencil}/>
                </button>
                <button
                  onClick={() => openDeletePrompt(benefit.id)}
                  data-tooltip-id="delete"
                  data-tooltip-content="Izbriši"
                  className="h-9 w-9 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-500 text-white animation duration-200 ease-in-out">
                  <Tooltip id="delete"/>
                  <FontAwesomeIcon icon={faTrashCan}/>
                </button>
              </div>
            </div>
          </li>
        )}
        {fixedBenefits.length === 0 &&
            <div className="h-[55vh] flex items-center justify-center text-gray-600 text-sm font-medium">
                Nema pronađenih benefita.
            </div>
        }
      </ul>
    </>
  );
};

export default FixedBenefits;